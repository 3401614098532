.controls {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  align-items: center;

  &--push {
    margin-right: auto;
  }

  &--pull {
    margin-left: auto;
  }
}

.alert-box .controls .button {
  margin-bottom: 0;
}
