$svg-icons: "add", "anchor_back", "anchor", "back", "check", "close",
  "folder_plus", "import", "info", "new_page", "package", "pen", "printer",
  "reload", "save", "search", "send", "trash", "view";

@mixin icons {
  @each $icon in $svg-icons {
    &--#{$icon} {
      background-image: url(static/assets/icons/#{$icon}.svg);
    }
  }
}

.icon {
  display: inline-block;
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
  background: center no-repeat;
  background-size: contain;

  @include icons();

  &--right {
    margin-right: 0;
    margin-left: 0.5em;
  }
}

.button.inline-icon.icon--standalone {
  padding: rem-calc(15) rem-calc(15) rem-calc(9) rem-calc(15);
  display: inline-block;
}
.icon--standalone,
.search-field {
  .icon {
    margin: 0;
    width: 1.5em;
    height: 1.5em;
  }
}

.button.inline-icon {
  display: inline-flex;
  line-height: 1.2;
  padding: rem-calc(20) rem-calc(26) rem-calc(16) rem-calc(26);
}

.text-link.inline-icon {
  display: inline-block;
  .icon {
    vertical-align: middle;
    transform: translateY(-0.1em);
  }
}
